import React, { Fragment } from "react";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import { withAuth } from "components/container/withAuth";
import Header from "components/layout/Header";
import LoginForm from "shared/LoginForm";
import DietPage from "components/pages/DietPage";
import FitnessPage from "pages/FitnessPage";
import MeasurementsPage from "pages/MeasurementsPage";
import FoodProductsPage from "pages/FoodProductsPage";
import UserRecipesPage from "pages/UserRecipesPage";
import EditRecipePage from "pages/EditRecipePage";
import MealsPage from "pages/MealsPage";
import DailyMenusPage from "pages/DailyMenusPage";
import HomePage from "pages/HomePage";
import WeekSummaryPage from "pages/WeekSummaryPage";
import SystemMessages from "components/ui/SystemMessages";
import ProfilePage from "pages/ProfilePage";
import MembershipPage from "pages/MembershipPage";
import MembershipsPage from "pages/MembershipsPage";
import InvoicePage from "pages/InvoicePage";
import OnbordingPage from "pages/OnbordingPage";
import CoachPage from "pages/CoachPage";
import ReportPage from "pages/ReportPage";
import MeetingPage from "pages/MeetingPage";
import Loading from "components/ui/Loading";
import ToolboxPage from "pages/ToolboxPage";
import CourseUpdate from "pages/CourseUpdate";
import RenewMembershipNotice from "components/ui/RenewMembershipNotice";
import UnpaidInvoiceNotice from "components/ui/UnpaidInvoiceNotice";
import RenewedMembershipNotice from "components/ui/RenewedMembershipNotice";
import AdminSupport from "pages/AdminSupport";
import moment from "moment";

const U = ({ auth: { status, user } = {}, ...props }) => {
  return (
    <div className="app">
      <Header user={user} />
      {status === "loading" && <Loading />}
      {status === "offline" && <LoginForm />}
      {status === "online" && (
        <Fragment>
          <SystemMessages slotId={4} />
          <UnpaidInvoiceNotice />
          <RenewedMembershipNotice />
          <RenewMembershipNotice />

          <Routes>
            <Route index element={<HomePage />} />
            <Route path="ukesammendrag/:year">
              <Route path=":week" element={<WeekSummaryPage />} />
            </Route>
            <Route path="/coach/">
              <Route path=":membershipId">
                <Route path="rapport/:reportId/">
                  <Route path=":sectionId" element={<ReportPage />} />
                  <Route index element={<ReportPage />} />
                </Route>
                <Route path="mote/:courseMeetingId" element={<MeetingPage />} />
                <Route index element={<CoachPage />} />
              </Route>
              <Route index element={<CoachPage />} />
            </Route>
            <Route path="/verktoy" element={<ToolboxPage />} />
            <Route path="/kosthold/">
              <Route path=":date" element={<DietRoute />} />
              <Route index element={<DietRoute />} />
            </Route>
            <Route path="min-mat">
              <Route path="matvarer" element={<FoodProductsPage />} />
              <Route path="oppskrifter">
                <Route path="endre">
                  <Route path=":objectId" element={<EditRecipePage />} />
                  <Route index element={<EditRecipePage />} />
                </Route>
                <Route index element={<UserRecipesPage />} />
              </Route>
              <Route path="maltider" element={<MealsPage />} />
              <Route path="mine-dager" element={<DailyMenusPage />} />
            </Route>
            <Route path="mosjon">
              <Route path=":date" element={<FitnessRoute />} />
              <Route index element={<FitnessRoute />} />
            </Route>
            <Route path="vekt-og-mal">
              <Route path=":year" element={<MeasurementsRoute />} />
              <Route path=":year/:week" element={<MeasurementsRoute />} />
              <Route index element={<MeasurementsRoute />} />
            </Route>
            <Route exact path="profil" element={<ProfilePage />} />
            <Route path="chatstreamio" element={<AdminSupport />} />
            <Route path="tema">
              <Route path=":week" element={<CourseUpdate />} />
              <Route index element={<CourseUpdate />} />
            </Route>
            <Route path="faktura">
              <Route path=":invoiceId" element={<InvoicePage />} />
              <Route index element={<InvoicePage />} />
            </Route>
            <Route path="onboarding">
              <Route path=":slug" element={<OnbordingRoute />} />
              <Route index element={<OnbordingRoute />} />
            </Route>
            <Route path="medlemskap">
              <Route path=":membershipId">
                <Route path=":placeId">
                  <Route path=":category">
                    <Route path=":courseType">
                      <Route path=":day">
                        <Route path=":courseId" element={<MembershipPage />} />
                        <Route index element={<MembershipPage />} />
                      </Route>
                      <Route index element={<MembershipPage />} />
                    </Route>
                    <Route index element={<MembershipPage />} />
                  </Route>
                  <Route index element={<MembershipPage />} />
                </Route>
                <Route index element={<MembershipPage />} />
              </Route>
              <Route index element={<MembershipsPage />} />
            </Route>
          </Routes>
        </Fragment>
      )}
    </div>
  );
};

function OnbordingRoute({ ...props }) {
  let params = useParams();
  return [
    "mal",
    "om-meg",
    "onsket-vekt",
    "aktivitetsniva",
    "nyhetsbrevsinnstillinger",
  ].includes(params.slug) ? (
    <OnbordingPage {...props} />
  ) : (
    <Navigate to={`/onboarding/mal/`} replace />
  );
}

function DietRoute({ ...props }) {
  let params = useParams();
  return params?.date ? (
    <DietPage {...props} />
  ) : (
    <Navigate to={`/kosthold/${defaultDate}/`} replace />
  );
}

function FitnessRoute({ ...props }) {
  let params = useParams();
  return params?.date ? (
    <FitnessPage {...props} />
  ) : (
    <Navigate to={`/mosjon/${defaultDate}/`} replace />
  );
}

const MeasurementsRoute = ({ ...props }) => {
  let params = useParams();
  return params?.year && params?.week ? (
    <MeasurementsPage {...props} />
  ) : (
    <Navigate to={`/vekt-og-mal/${defaultYear}/${defaultWeek}`} replace />
  );
};

const defaultDate = moment().format("YYYY-MM-DD");
const defaultYear = moment().format("YYYY");
const defaultWeek = moment().format("W");

export default withAuth(U);
