import { gql, useMutation, useQuery } from "@apollo/client";
import { useSearchParams } from "react-router-dom";

const GET_USER = gql`
  query UserQuery {
    user {
      activeGold
      activeCourse
      activeNettcourse
      activeInstructor
      roles
      onboardingDone
      userData {
        userId
        firstName
        kcalMenu
        favorites {
          objId
          objType
        }
        newsletterDinnerTips
        newsletterWeekly
        newsletterInformation
        newsletterSms
        toolbox {
          title
          icon
          files {
            title
            file
          }
        }
      }
      defaultCoachingMembership {
        membershipId
      }
      streamioToken
      streamioAdminToken
    }
  }
`;

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    auth {
      login(email: $email, password: $password) {
        status
        message
      }
    }
  }
`;

const LOGOUT = gql`
  mutation Login {
    auth {
      logout {
        status
        message
      }
    }
  }
`;

async function handleLogin(doLogin, userResult, email, password) {
  const { data } = await doLogin({ variables: { email, password } });
  data?.auth?.login?.status === "success" && userResult.refetch();
}

async function handleLogout(doLogout, userResult) {
  const { data } = await doLogout();
  if (data?.auth?.logout?.status === "success") {
    userResult.refetch();
    window.location = "/";
  }
}

function getLoginError(loginResult, logoutResult) {
  const loginStatus = loginResult.data?.auth?.login?.status;
  const logoutStatus = logoutResult.data?.auth?.logout?.status;
  if (loginStatus === "error") return loginResult.data?.auth?.login?.message;
  if (logoutStatus === "error") return logoutResult.data?.auth?.logout?.message;
  return undefined;
}

function getStatus({ loading, data }) {
  if (loading) return "loading";
  if (!data?.user?.userData?.userId) return "offline";
  return "online";
}

function getUser(userResult) {
  return {
    defaultCoachingMembership:
      userResult?.data?.user?.defaultCoachingMembership,
    favorites: userResult?.data?.user?.userData?.favorites || [],
    toolbox: userResult?.data?.user?.userData?.toolbox || [],
    hasPermissions: getUserPermissions(userResult?.data?.user?.roles),
    kcalMenu: userResult?.data?.user?.userData?.kcalMenu,
    onboardingDone: userResult?.data?.user?.onboardingDone,
    ownerId: userResult?.data?.user?.userData?.userId,
    roles: userResult?.data?.user?.roles || [],
    firstName: userResult?.data?.user?.userData?.firstName,
    userId: userResult?.data?.user?.userData?.userId,
    activeGold: userResult?.data?.user?.activeGold,
    activeCourse: userResult?.data?.user?.activeCourse,
    activeNettcourse: userResult?.data?.user?.activeNettcourse,
    activeInstructor: userResult?.data?.user?.activeInstructor,
    newsletterDinnerTips:
      userResult?.data?.user?.userData?.newsletterDinnerTips,
    newsletterWeekly: userResult?.data?.user?.userData?.newsletterWeekly,
    newsletterInformation:
      userResult?.data?.user?.userData?.newsletterInformation,
    newsletterSms: userResult?.data?.user?.userData?.newsletterSms,
    streamioToken: userResult?.data?.user?.streamioToken,
    streamioAdminToken: userResult?.data?.user?.streamioAdminToken,
  };
}

function getUserPermissions(roles = []) {
  const allowedRoles = [
    "placeholderzzz",
    "gold",
    "instructor",
    "internal",
    "nettcourse",
    "course",
  ];

  return Boolean(roles.find((role) => allowedRoles.indexOf(role) > 0));
}

// function setWithExpiry(key, value, ttl) {
// 	const now = new Date()

// 	// `item` is an object which contains the original value
// 	// as well as the time when it's supposed to expire
// 	const item = {
// 		value: value,
// 		expiry: now.getTime() + ttl,
// 	}
// 	localStorage.setItem(key, JSON.stringify(item))
// }

// function getWithExpiry(key) {
// 	const itemStr = localStorage.getItem(key)
// 	// if the item doesn't exist, return null
// 	if (!itemStr) {
// 		return null
// 	}
// 	const item = JSON.parse(itemStr)
// 	const now = new Date()
// 	// compare the expiry time of the item with the current time
// 	if (now.getTime() > item.expiry) {
// 		// If the item is expired, delete the item from storage
// 		// and return null
// 		localStorage.removeItem(key)
// 		return null
// 	}
// 	return item.value
// }

function useAuth() {
  const userResult = useQuery(GET_USER);
  const [searchParams] = useSearchParams();
  const [doLogin, loginResult] = useMutation(LOGIN);
  const [doLogout, logoutResult] = useMutation(LOGOUT);
  const cidSearch = searchParams.get("cid");
  if (cidSearch) {
    //setWithExpiry('cid', cidSearch, 345600000)
    window.cidSearch = cidSearch;
  }
  //const cid = getWithExpiry('cid')
  const cid = window.cidSearch;
  const error = userResult.error;
  const loadingLogin = loginResult.loading || logoutResult.loading;
  const login = (email, password) =>
    handleLogin(doLogin, userResult, email, password);
  const logout = () => handleLogout(doLogout, userResult);
  const loginError = getLoginError(loginResult, logoutResult);
  const refetch = userResult.refetch;
  const status = getStatus(userResult);
  const user = getUser(userResult);

  return {
    error,
    loadingLogin,
    login,
    loginError,
    logout,
    refetch,
    status,
    user,
    cid,
  };
}

export default useAuth;
