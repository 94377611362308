import React from 'react'
import './styles.scss'

const PageBody = ({ status, children }) => (
  <div className="PageBody" data-status={status}>
    {children}
  </div>
)

export default PageBody
