import { ApolloClient, InMemoryCache } from '@apollo/client'
import { ApolloProvider } from '@apollo/client/react'
import { createUploadLink } from 'apollo-upload-client'

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache({
    typePolicies: {
      RealQuery: {
        keyFields: [],
      },
      Kitchen: {
        keyFields: [],
      },
      Diary: {
        keyFields: [],
      },
      DiaryMutation: {
        keyFields: [],
      },
      NetcourseMutations: {
        keyFields: [],
      },
      RegistrationMutation: {
        keyFields: [],
      },
      UserMutation: {
        keyFields: [],
      },
      User: {
        keyFields: [],
      },
      UserData: {
        keyFields: ['userId'],
      },
      Netcourse: {
        keyFields: [],
      },
    },
  }),
  link: createUploadLink({
    uri: process.env.REACT_APP_API_URL,
    credentials: 'include',
  }),
})

export default function ApiProvider({ children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
