import { useState } from 'react'

function useLoading() {
  const [loading, setLoading] = useState(false)
  const show = () => setLoading(true)
  const hide = () => setLoading(false)
  return { loading, show, hide }
}

export default useLoading
