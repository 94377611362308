import React from 'react'
import s from './Loading.module.scss'

export default function Loading() {
  return <div className={s.loading}></div>
}


export function LoadingInline(){
  return <div className={s.loadingInline}></div>
}